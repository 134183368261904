@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --bgc: #2E2E2E;
  --color: #ffff;
  --bgHero: url(/static/media/cover.2d3f5fbc.png);
  --bgProfile: url(/static/media/logo.1b0def06.png);
  --borderColor: #FFD602;
  --gradient: linear-gradient(90deg, #FFD905 0.02%, #FFD905 100.02%);
  --font:'Heebo', sans-serif;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2E2E2E;
  background-color: var(--bgc);
  color: #ffff;
  color: var(--color);
  font-family: 'Heebo', sans-serif;
  font-family: var(--font);
}

.btn {
  height: 46px;
  width: 204px;
  border-radius: 5px;
  background: linear-gradient(90deg, #FFD905 0.02%, #FFD905 100.02%);
  background: var(--gradient);
  border-radius: 5px;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
  color: #2E2E2E;
  color: var(--bgc);
  font-weight: 500;
}
.svc-btn{
  background: transparent;
  color: #fff;
  border: 2px solid #fff;
}
.svc-btn:hover{
  background: linear-gradient(90deg, #FFD905 0.02%, #FFD905 100.02%);
  background: var(--gradient);
  color: #fff;
  border: 0px solid #fff;
}
h1 {
  font-size: 35px;
  font-weight: 500;
}

p {
  font-size: 20px;
  font-weight: 300;
}
.Footer a{
  color: #FFD602;
  color: var(--borderColor);
}
.cover{
    position: relative;
    width: 95vw;
    height: 250px;
    top: 10px;
    background-image: var(--bgHero);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    border-radius: 10px;
    display: flex;
    justify-content: center;
}
.profile{
    position: absolute;
    width: 163px;
    height: 163px;
    border-radius: 50%;
    top: 140px;
    background-image: var(--bgProfile);
    background-size: contain;
}
.profile::before {
    content: "";
    position: absolute;
    top: -7px;
    left: -7px;
    right: -7px;
    bottom: -7px;
    border-radius: 50%;
    border: 2px solid var(--borderColor);
  }
.SocialIcons{
    margin-top: 28px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 34px;
    gap: 34px;
    width: 100vw;
    direction: rtl;
    width: 80vw;
}
.socialIcon{
    cursor: pointer;
    transition: .25s all ease-out;
display: flex;
flex-direction: column;
}
.socialIcon:hover{
    transition: .3s all ease-in;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
span{
    margin-top: 10px;
    font-size: 13px;
}
.heading-actions{
    display: none !important;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: white;
    color: #2E2E2E;
    padding: 20px;
    width: 400px;
    height: 525px;
    overflow-y: scroll;
    max-width: 90%;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    direction: rtl;
    font-family: var(--font);
  }
